import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import Root from './Root';
import NotFoundPage from '@root/pages/NotFoundPage';
import { AdminRoutes } from '@admin';
import { ClientRoutes } from '@client';


const router = createBrowserRouter(createRoutesFromElements(
  <Route
    path="/"
    element={<Root />}
    errorElement={<Navigate to="/" />}
  >
    <Route index element={<NotFoundPage />} />
    {ClientRoutes}
    {AdminRoutes}
  </Route>
));

export default router;
