import addObjectByCondition from './addObjectByCondition';
import findById from './findById';
import createImage from './createImage';
import imageOnLoad from './imageOnLoad';
import toFormData from './toFormData';
import parseQuery from './parseQuery';
import debounce from './debounce';
import componentLazyLoad from './componentLazyLoad';
import downloadByLink from '@root/shared/utils/downloadByLink';
import mockFn from '@root/shared/utils/mockFn';
import replaceOrAddInArr from '@root/shared/utils/replaceOrAddInArr';

const utils = {
  addObjectByCondition,
  findById,
  createImage,
  imageOnLoad,
  toFormData,
  parseQuery,
  debounce,
  componentLazyLoad,
  downloadByLink,
  mockFn,
  replaceOrAddInArr,
};

export default utils;