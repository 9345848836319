import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import utils from '@root/shared/utils';

const AdminApp = utils.componentLazyLoad(() => import('./AdminApp'));
const MainLayout = utils.componentLazyLoad(() => import('../components/Layout/MainLayout'));
const HomePage = utils.componentLazyLoad(() => import('../pages/HomePage'));
const UserPage = utils.componentLazyLoad(() => import('../pages/UserPage'));
const ProjectPage = utils.componentLazyLoad(() => import('../pages/ProjectPage'));
const RoomPage = utils.componentLazyLoad(() => import('../pages/RoomPage'));
const SyncPage = utils.componentLazyLoad(() => import('../pages/SyncPage'));
const CollectionsPage = utils.componentLazyLoad(() => import('../pages/CollectionsPage'));

const AdminRoutes = (
  <Route path='admin' lazy={AdminApp}>
    <Route index element={<Navigate to='home' />} />
    <Route path='home' lazy={MainLayout}>
      <Route index lazy={HomePage} />

      <Route path="user/:userId" >
        <Route index lazy={UserPage} />

        <Route path="project/:projectId">
          <Route index lazy={ProjectPage} />

          <Route path="room/:roomId">
            <Route index lazy={RoomPage}/>
          </Route>

        </Route>

      </Route>

    </Route>

    <Route lazy={MainLayout} path="sync">
      <Route index lazy={SyncPage} />
    </Route>

    <Route lazy={MainLayout} path="collections">
      <Route index lazy={CollectionsPage} />
    </Route>

    <Route path='*' element={<Navigate to='home' />} />
  </Route>
);

export default AdminRoutes;
