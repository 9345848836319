import IMessage from './Message';

abstract class Messenger<GroupType, OutgoingEventType, IncomingEventType> {
  abstract group: string;
  protected readonly targetOrigin: string = process.env.REACT_APP_STORE_ORIGIN_URL ? process.env.REACT_APP_STORE_ORIGIN_URL : '*';

  protected postMessage(message: IMessage<GroupType, OutgoingEventType>): void {
    parent.postMessage(message, this.targetOrigin);
  }

  eventTypeHandler<EnteredMessageData>(
    eventType: IncomingEventType,
    handler: (message: IMessage<GroupType, IncomingEventType, EnteredMessageData>) => void,
    onSubscribe?: () => void,
  ) {
    if (onSubscribe) onSubscribe();
    return (message: IMessage<string, string>) => {
      if (message.group === this.group && message.event === eventType) {
        handler(message as IMessage<GroupType, IncomingEventType, EnteredMessageData>);
      }
    };
  }
}

export default Messenger;