import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import utils from '@root/shared/utils';

const ClientApp = utils.componentLazyLoad(() => import('./ClientApp'));
const DashboardRoot = utils.componentLazyLoad(() => import('./roots/DashboardRoot'));
const ProjectsPage = utils.componentLazyLoad(() => import('../pages/ProjectsPage'));
const AddToProjectsPage = utils.componentLazyLoad(() => import('../pages/AddToProjectsPage'));
const ProjectPage = utils.componentLazyLoad(() => import('../pages/ProjectPage'));
const RoomPage = utils.componentLazyLoad(() => import('../pages/RoomPage'));
const AccessRequestPage = utils.componentLazyLoad(() => import('../pages/AccessRequestPage'));
const RequestAccessMiddleware = utils.componentLazyLoad(() => import('../router/middlewares/RequestAccessMiddleware'));
const ClientAcceptedMiddleware = utils.componentLazyLoad(() => import('../router/middlewares/ClientAcceptedMiddleware'));

const ClientRoutes = (
  <Route path='client' lazy={ClientApp}>
    <Route index element={<Navigate to='projects' />} />

    <Route path='projects' lazy={DashboardRoot}>
      <Route index lazy={ProjectsPage} />

      <Route path=':projectId'>
        <Route index lazy={ProjectPage} />
        <Route path='rooms/:roomId' lazy={RoomPage} />
      </Route>
    </Route>

    <Route path="access-request" lazy={RequestAccessMiddleware} >
      <Route index lazy={AccessRequestPage} />
    </Route>
    <Route path="add-to-projects" lazy={ClientAcceptedMiddleware} >
      <Route index lazy={AddToProjectsPage} />
    </Route>
    <Route path='*' element={<Navigate to='projects' />} />
  </Route>
);

export default ClientRoutes;
