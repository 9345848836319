export default function toFormData<DataType extends object>(data: DataType, nullableFields?: (keyof DataType)[] | true): FormData {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {

    if (value instanceof File) {
      formData.append(key, value, value.name);
      return;
    }

    if (value instanceof Blob || typeof value === 'string') {
      formData.append(key, value);
      return;
    }

    if (typeof value === 'number' && !Number.isNaN(value) || typeof value === 'boolean') {
      formData.append(key, String(value));
      return;
    }

    if (value !== undefined && value !== null && !Number.isNaN(value)) return;

    if (!nullableFields || nullableFields !== true && !(nullableFields as string[]).includes(key)) return;

    formData.append(key, 'null');
  });

  return formData;
}