import React, { useEffect } from 'react';
import router from './router/router';
import {RouterProvider} from 'react-router-dom';
import InfoEventsMessenger from '@client/models/Messenger/InfoEventsMessenger';

const infoEventsMessenger = new InfoEventsMessenger();

const App = () => {
  useEffect(() => {
    infoEventsMessenger.sendEvent(InfoEventsMessenger.EventType.APP_MOUNTED);
  }, []);
  return <RouterProvider router={router} />;
};

export default App;
