export default function debounce<Arguments extends any[]>(func: (...args: Arguments) => void, timeout = 300): (...args: Arguments) => void {
  let debounced = false;

  return (...args: Arguments) => {
    if (debounced) return;
    debounced = true;
    setTimeout(() => {
      func(...args);
      debounced = false;
    }, timeout);
  };
}