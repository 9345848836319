import Messenger from './Messenger';

enum OUTGOING_EVENT_TYPE {
  APP_MOUNTED = 'app-mounted',
  CLIENT_LOGGED_IN = 'client-logged-in',
  ACCESS_REQUEST_PAGE_OPENED = 'access-request-page-opened'
}

enum INCOMING_EVENT_TYPE {}

type GROUP_TYPE = 'info-events';

class InfoEventsMessenger extends Messenger<GROUP_TYPE, OUTGOING_EVENT_TYPE, INCOMING_EVENT_TYPE> {
  readonly group: GROUP_TYPE = 'info-events';

  static get EventType() {
    return {...OUTGOING_EVENT_TYPE, ...INCOMING_EVENT_TYPE};
  }

  sendEvent(event: OUTGOING_EVENT_TYPE): void {
    this.postMessage({
      group: this.group,
      event: event,
    });
  }
}


export default InfoEventsMessenger;