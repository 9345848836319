
const parseQuery = <Return extends Record<string, string | undefined>>(queryStr: string): Partial<Return> => {
  const query = queryStr.split('?')[1];
  if (query) {
    const paramsStr = query.split('&');
    const params: Record<string, string> = {};
    paramsStr.forEach(item => {
      const [param, value] = item.split('=');
      params[param] = value;
    });
    return params as Partial<Return>;
  } else return {} as Partial<Return>;
};

export default parseQuery;
