import React from 'react';

const NotFoundPage = () => {
  return (
    <div style={{fontSize: '22px', fontWeight: '700', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
      Page not found (404)
    </div>
  );
};

export default NotFoundPage;